var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_vm._m(0),(_vm.formwin1)?_c('div',{staticClass:"windform"},[_c('div',{staticClass:"conts"},[_c('div',{staticClass:"clos"},[_c('img',{attrs:{"src":require("../assets/img2/closeBtn.png")},on:{"click":function($event){_vm.formwin1 = false}}})]),_c('h1',[_vm._v("전자지문 보관")]),_c('p',[_vm._v("* SHA-256 HASH 값 추출")]),_vm._m(1)])]):_vm._e(),(_vm.formwin2)?_c('div',{staticClass:"windform"},[_c('div',{staticClass:"conts"},[_c('div',{staticClass:"clos"},[_c('img',{attrs:{"src":require("../assets/img2/closeBtn.png")},on:{"click":function($event){_vm.formwin2 = false}}})]),_c('h1',[_vm._v("비밀원본 보관")]),_c('p',[_vm._v("* SHA-256 HASH 값 추출")]),_c('p',[_vm._v("* 3중 보안 서버에 안전 보관")]),_vm._m(2)])]):_vm._e(),_c('div',{staticClass:"top-img"},[_c('div',{staticClass:"zz content"},[_c('h1',[_vm._v("온누리국제영업비밀보호센터의 차별화된 서비스를 경험하세요")]),_vm._m(3),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.golink('upload')}}},[_vm._v("보관의뢰 하기")])])]),_c('div',{attrs:{"id":"con1"}}),_c('div',{staticClass:"titleLine"},[_vm._v("영업비밀로 보호 가능한 대표적 정보")]),_vm._m(4),_c('div',{attrs:{"id":"con2"}}),_c('div',{attrs:{"id":"con3"}}),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_c('div',{attrs:{"id":"payment-method"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_vm._v(" 의뢰하신 영업 비밀 파일의 전자 지문 값을 추출,"),_c('br'),_vm._v(" 해당 전자 지문을 센터에 보관하는 서비스 입니다."),_c('br'),_vm._v(" 센터에 보관 중인 전자 지문 값과의 대조를 통해,"),_c('br'),_vm._v(" 소유하신 파일의 원본의 증명서를 발급합니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_vm._v(" 의뢰하신 영업 비밀 파일의 전자 지문 값을 추출,"),_c('br'),_vm._v(" 추출된 전자 지문값과 함께 원본 파일의 분실 위험없이"),_c('br'),_vm._v(" 온누리 보안 센터에 보관을 의뢰하는 서비스 입니다."),_c('br'),_vm._v(" 보관하신 영업 비밀은 수시로 열람/다운로드가 가능하며,"),_c('br'),_vm._v(" 원본 증명서를 발급시 붙임물로써의 활용이 가능합니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v(" 영업비밀의 안전한 보관부터 해외법원 제출용 원본증명서 발급까지"),_c('br'),_vm._v(" 가장 필요한 영업비밀 보호서비스를 제공합니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('img',{staticClass:"pc imgCon",staticStyle:{"margin":"20px 0"},attrs:{"src":require("../assets/img2/service_info.png")}}),_c('img',{staticClass:"mobile",staticStyle:{"width":"94vw"},attrs:{"src":require("../assets/img2/service_info.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content",staticStyle:{"justify-content":"center","align-items":"center","display":"flex","flex-direction":"column"}},[_c('img',{staticClass:"conImg",attrs:{"src":require("../assets/img2/planImage1.png")}}),_c('img',{staticClass:"conImg",staticStyle:{"margin":"40px 0"},attrs:{"src":require("../assets/img2/planImage2.png")}})])
}]

export { render, staticRenderFns }